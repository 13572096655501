import './App.css';
import React, { useEffect, useRef } from 'react';
function App() {
  const CA = "WwRDRevsp28WRJEmowNTaLLgspykTjnd8BVZxXQpump"
  const openTelegram = () => {
    window.open("http://t.me/flappyonsol", "_blank");
  };
  const openX = () => {
    window.open("https://x.com/Flappyonsolana", "_blank");
  };
  const openBuy = () => {
    window.open("http://raydium.io/swap/?inputMint=sol&outputMint=WwRDRevsp28WRJEmowNTaLLgspykTjnd8BVZxXQpump", "_blank");
  };
  const titles = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(
            entry.target.dataset.animation === 'left' ? 'slide-in-left' : 'slide-in-right'
          );
        }
      });
    }, { threshold: 0.5 });

    titles.current.forEach(title => {
      observer.observe(title);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className='App'>
      <div className="Header">
        <div className='HeaderContents'>
          <img src={require('./assets/Flappy.png')} alt='flappy' />
          <img src={require('./assets/Logo.png')} alt='logo' />
        </div>
        <div className='BuyBtn'onClick={openBuy}> Buy Flappy</div>
        <div className='SMContainer'>
          <div className='SMIcon XLogo' onClick={openX}></div>
          <div className='SMIcon TelegramLogo' onClick={openTelegram}></div>
        </div>
      </div>
      <div className='Seperator'>
        <h1 className='Ticker'>$FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY $FLAPPY </h1>
      </div>
      <div className='Tokenomics'>
        <h1
          ref={el => titles.current.push(el)}
          data-animation="left"
          className='TitleText TokenTitle'>
          Tokenomics
        </h1>
        <div className='TokenomicsContent'>
          <div className='TokenomicsData'>
            <h1>Total Supply</h1>
            <h3>1,000,000,000 tokens</h3>
          </div>
          <div className='TokenomicsData'>
            <h1>CA</h1>
            <h3>{CA}</h3>
          </div>
        </div>
        <div className='BuyBtn' onClick={openBuy}> Buy Flappy</div>
      </div>
      <div className='Guide'>
        <h1
          ref={el => titles.current.push(el)}
          data-animation="right"
          className='TitleText GuideTitle'>
          How to buy
        </h1>
        <div className='GuideDataContainer'>
          <div className='GuideData'>
            <h3>1. Create a wallet with Phantom</h3>
            <p class="GuideDataItemSUB">Visit <a href="https://phantom.app/" target="_blank" rel="noreferrer" className='PhantomLink'>Phantom.App</a> and follow the simple steps to create a new account with the phantom app or browser extension.</p>
          </div>
          <div className='GuideData'>
            <h3>2. Get some $SOL</h3>
            <p class="GuideDataItemSUB">Tap the BUY button in the app to purchase Solana, or deposit $SOL to your Phantom wallet from the crypto exchange of your choice.</p>
          </div>
          <div className='GuideData'>
            <h3>3. Swap $SOL for $FLAPPY</h3>
            <p class="GuideDataItemSUB">Tap the SWAP icon in your Phantom wallet and paste the $FLAPPY token address. Swap your $SOL for $FLAPPY.</p>
          </div>
          <div className='GuideData'>
            <h3>4. You are now an $FLAPPY holder!</h3>
            <p class="GuideDataItemSUB">Welcome to the flappy flock!</p>
          </div>
        </div>
      </div>
      <div className='AnimationContainer' />
      <div className='SocialsContainer'>
        <h1
          ref={el => titles.current.push(el)}
          data-animation="left"
          className='TitleText SocialsTitle'>
          SOCIALS
        </h1>
        <div className='SMContainer'>
          <div className='SMIcon XLogo' onClick={openX}></div>
          <div className='SMIcon TelegramLogo' onClick={openTelegram}></div>
        </div>
        <div className='imgContainer '>
          <div className='imgHolder r3degr img1'></div>
          <div className='imgHolder r5deg img2'></div>
          <div className='imgHolder r4degr img3'></div>
          <div className='imgHolder r2deg img4'></div>
        </div>
        <h2 className='Footer'>2024 © All Rights Reserved | WebsiteDomain.ToBe</h2>

      </div>
    </div>
  );
}

export default App;
